import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// 引入中文文件
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
 

document.title = '锐眼洞见';

const pinia = createPinia();
const app = createApp(App);

 

//设置中文
app.use(ElementPlus,{
    locale: zhCn,
});
// // 禁用鼠标滚轮缩放

app.use(pinia);
app.use(router);
app.mount('#app');

 