<template>
  <div id="app" class="app-container">
 
    <div class="app-opctiy">
      <div class="main-content">
     
          <router-view></router-view>
      </div>
    </div>
    
    
    
  </div>
</template>

 
 
 
<style>
body {
    font-family: 'Inter', "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

html, body,div,p,span,ul,li,h1{
  margin: 0;
  padding: 0;
  border:0;
}
 
.app-container {
 
  text-align: center;
  color: #2c3e50;
  /* background: url('./assets/background.png') no-repeat;  */
 
}

.app-opctiy{
 
  /* background-size: cover; */
 
}


.router-view {
  padding: 20px;
}
/* 隐藏默认滚动条 */
::-webkit-scrollbar {
  display: none;
}

/* 自定义滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* 自定义滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* 当鼠标悬停在滚动条上时改变滑块颜色 */
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 


</style>