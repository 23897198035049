// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/homePage/home.vue"),
  },
  {
    path: '/securityServices',
    name: 'securityServices',
    component: () => import("@/views/securityServices/securityServices.vue"),
  },
  {
    path: '/services',
    name: 'ServicesPage',
    component: () => import("@/views/ServicesPage.vue"),

  },
  {
    path: '/myorder',
    name: 'myOrder',
    component: () => import("@/views/myOrder/myOrder.vue"),

  },
  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: () => import("@/views/userAgreement.vue"),

  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import("@/views/privacyPolicy.vue"),

  },
  {
    path: '/productServiceAgreement',
    name: 'productServiceAgreement',
    component: () => import("@/views/productServiceAgreement.vue"),

  },
 
  
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;

 